<template>
  <div class="detail-div">
    <CRow>
      <div>
        <CModal
            :title="
            submitType === 'Update'
              ? $lang.post.crud.edit
              : $lang.common.general.reason
          "
            size="lg"
            :show.sync="largeModal"
            :no-close-on-backdrop="true"
            color="primary"
        >
          <CCardBody>
            <CRow>
              <CCol md="12">
                <c-textarea
                    v-model="post.reason"
                    :rows="5"
                    :cols="10"
                    placeholder="Add Reason"
                ></c-textarea>
              </CCol>
              <CCol md="6">
                <div class="form-group">
                  <CButton
                      color="primary"
                      v-c-tooltip="$lang.post.crud.post_status"
                      :disabled="submitted"
                      v-on:click="blockPost(statusId)"
                  >
                    Submit
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
          <template #footer style="display: none">
            <CButton
                @click="darkModal = false"
                color="danger"
                style="display: none"
            >Discard
            </CButton
            >
            <CButton
                @click="darkModal = false"
                color="success"
                style="display: none"
            >Accept
            </CButton
            >
          </template>
        </CModal>
      </div>

      <CCol md="12" class="align-self-center">
        <CRow>
          <CCol sm="6" md="4" lg="4" v-show="post.user">
            <p class="custome-labal">
              {{ $lang.common.form.user }}
            </p>
            <p class="custome-detail">
              <ProfilerDetail :username="post.user" :profilerId="post.userId"></ProfilerDetail>
            </p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.post.form.postType }}</p>
            <p class="custome-detail">{{ post.postType }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.post.form.noOfPosition }}</p>
            <p class="custome-detail">{{ postDetail.noOfPost }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.post.form.minPrice }}</p>
            <p class="custome-detail">{{ postDetail.minPrice }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.post.form.maxPrice }}</p>
            <p class="custome-detail">{{ postDetail.maxPrice }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="post.branchInfo" v-if="post.whose!=='Individual'">
            <p class="custome-labal">{{ $lang.post.form.branch }}</p>
            <BranchComponent :data="post.branchInfo"></BranchComponent>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="post.priority">
            <p class="custome-labal">{{ post.postType }} {{ $lang.post.form.priority }}</p>
            <p class="custome-detail">{{ post.priority.title }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.post.form.visibility }}</p>
            <p class="custome-detail">
              {{
                post.visibility === 1 ? "Local" : (post.visibility === 2 ? "National" : (post.visibility === 3 ? "International" : ""))
              }}</p>
          </CCol>

          <CCol sm="6" md="4" lg="4" v-if="post.whose==='Company'">
            <p class="custome-labal">{{ $lang.post.form.individualshowToOther }}</p>
            <p class="custome-detail">{{ post.showToOther === 1 ? 'Yes' : 'No' }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-if="post.whose==='Individual'">
            <p class="custome-labal">{{ $lang.post.form.companyshowToOther }}</p>
            <p class="custome-detail">{{ post.showToOther === 1 ? 'Yes' : 'No' }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.post.form.information }}</p>
            <p class="custome-detail">{{ post.ratingLabel }}</p>
          </CCol>

          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.post.form.industry }}</p>
            <p class="custome-detail">{{ post.industry }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="post.subIndustry">
            <p class="custome-labal">{{ $lang.post.form.subIndustry }}</p>
            <p class="custome-detail">{{ post.subIndustry }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="post.resourceType">
            <p class="custome-labal">{{ $lang.post.form.resourceType }}</p>
            <p class="custome-detail">{{ post.resourceType }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="post.duration">
            <p class="custome-labal">{{ post.postType }} {{ $lang.post.form.duration }}</p>
            <p class="custome-detail">{{ post.duration.title }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="post.currency">
            <p class="custome-labal">{{ $lang.post.form.currency }}</p>
            <p class="custome-detail">{{ post.currency.title }} ({{ post.currency.symbol }})</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="post.budget">
            <p class="custome-labal">{{ $lang.post.form.budget }}</p>
            <p class="custome-detail">{{ post.symbol }}&nbsp;{{ post.budget }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="post.skills">
            <p class="custome-labal">{{ $lang.post.form.skills }}</p>
            <p class="custome-detail">{{ post.skills }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="post.isGST">
            <p class="custome-labal">{{ $lang.post.form.includedTax }}</p>
            <p class="custome-detail">{{ post.isGST }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="post.tax">
            <p class="custome-labal">{{ $lang.post.form.tax }}</p>
            <p class="custome-detail">{{ post.tax }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="post.isRemoteWork">
            <p class="custome-labal">{{ $lang.post.form.isRemoteWork }}</p>
            <p class="custome-detail">{{ post.isRemoteWork }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-if="post.remoteWorkAddress">
            <p class="custome-labal">{{ $lang.post.form.remoteWorkAddress }}</p>
            <p class="custome-detail" v-if="post.remoteWorkAddress">
              {{ post.remoteWorkAddress }}
            </p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.post.form.status }}</p>
            <p class="custome-detail">
              <CBadge :color="statusBudget(post.isStatus)">{{ post.isStatus }}</CBadge>
            </p>
          </CCol>

          <CCol sm="12" md="12" lg="12" v-show="post.description">
            <p class="custome-labal">{{ $lang.post.form.description }}</p>
            <Description
                :title="$lang.post.form.description"
                :content="post.description"
                :length="90"
            />
          </CCol>
        </CRow>

        <EntityTimeStamp :createdAt="post.createdAt" :updatedAt="post.updatedAt"></EntityTimeStamp>

        <CRow v-show="post.blockedReason">
          <CCol sm="12" align="left">
            <strong>Blocked Reason: </strong> {{ post.blockedReason }}
          </CCol>
        </CRow>

        <CRow v-if="!post.blockedReason">
          <CCol sm="12" align="left">
            <CButton
                :color="statusColor"
                v-c-tooltip="statusLabel"
                v-on:click="beforeBlocked()"
            >
              {{ statusLabel }}
            </CButton>
          </CCol>
        </CRow>


      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {posts} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import VueNotifications from "vue-notifications";
import Description from "../../Description";
import ProfilerDetail from "/src/views/ProfilerDetail.vue";
import EntityTimeStamp from "/src/components/EntityTimeStamp";
import BranchComponent from "/src/views/branch/Component";

Vue.use(VueNotifications);
Vue.use(VueForm);

export default {
  name: "DetailTab",
  mixins: [Mixin],
  components: {
    Description,
    ProfilerDetail,
    EntityTimeStamp,
    BranchComponent
  },
  data() {
    return {
      postDetail: [],
      statusId: "",
      statusLabel: "Block",
      statusColor: "danger",
      submitted: false,
      largeModal: false,
      submitType: "",
      statusOption: [],
      showTax: true,
      err_msg: "",
      dismissCountDown: 0,
      postTypeList: [],
      industryOptions: [],
      subIndustryOptions: [],
      resourceTypeOptions: [],
      currencyOptions: [],
      durationOptions: [],
      priorityOptions: [],
      workTypeOptions: [],
      userOptions: [],
      skillsOptions: [],
      selectedSkills: [],
      getSkills: [],
      self: this,
      module: posts,
      post: {
        reason: "",
        branchInfo: "",
        description: "",
        postType: "",
        subIndustry: "",
        resourceType: "",
        currency: "",
        priority: "",
        duration: "",
        industry: "",
        isActive: 1,
        workType: "",
        budget: "",
        user: "",
        skills: "",
        isGST: null,
        isStatus: "",
        ratingLabel: "",
        company: "",
        statusId: "",
        userId: "",
        whose: "",
        symbol: "",
        createdAt: "",
        updatedAt: "",
        blockedReason: '',
      },
    };
  },
  mounted() {
    const type = {
      REQUIRED: "Required",
      AVAILABLE: "Available",
    };
    let self = this;
    self.statusOption.push(
        {value: "1", label: "Active"},
        {value: "0", label: "InActive"},
        {value: "2", label: "Block"}
    );
    self.dismissCountDown = 0;
    const id = this.$route.params.id;
    self.postTypeList.push(
        {value: "1", label: type.REQUIRED},
        {value: "2", label: type.AVAILABLE}
    );
    self.dismissCountDown = 0;
    self.editId = id;
    axios
        .get(this.viewUrlApi(this.module, id))
        .then((response) => {
          // eslint-disable-next-line no-empty
          if (response.data) {
            let responseData = response.data.data;
            self.postDetail = responseData;
            self.post.description = responseData.description ?? '';
            self.post.branchInfo = responseData.branchInfo ?? '';
            self.post.visibility = responseData.visibility ?? '';
            self.post.showToOther = responseData.showToOther ?? '';
            self.post.ratingLabel = responseData.ratingLabel ?? '';
            self.post.budget = responseData.budget ?? '';
            self.post.isActive = responseData.isActive ?? '';
            self.post.tax = responseData.GstTax ?? '';
            self.post.isGST = responseData.isGST ?? '';
            self.post.isRemoteWork = responseData.remoteWork ?? '';
            self.post.remoteWorkAddress = responseData.remoteWorkAddress ?? '';
            self.post.postType = responseData.postTypeName ?? '';
            self.post.status = responseData.status ?? '';
            self.post.isStatus = responseData.isActiveStatus ?? '';
            self.post.industry = responseData.industry.title ?? '';
            self.post.duration = responseData.duration ?? '';
            self.post.priority = responseData.priority ?? '';
            self.post.currency = responseData.currency ?? '';
            self.post.symbol = responseData.currencySymbol ?? '';
            self.post.createdAt = responseData.createdAt;
            self.post.updatedAt = responseData.updatedAt;
            self.post.blockedReason = responseData.blockedReason ?? '';

            if (responseData.isGST === "No") {
              self.showTax = false;
            }
            if (self.post.isStatus === "Active") {
              self.post.isStatus = 'Open';
              self.statusLabel = this.getStatusLabel(2);
              self.statusId = "2";
              self.statusColor = "danger";
            } else {
              self.statusId = "1";
              self.statusLabel = this.getStatusLabel(1);
              self.statusColor = "success";
            }
            self.post.whose = responseData.user.whose;
            if (responseData.user.company)
              self.post.user = responseData.user.userWithCompany;
            else if (responseData.user.name)
              self.post.user = responseData.user.userWithCompany;

            self.post.userId = responseData.user.id;
            let subIndustrylist = [];
            responseData.subindustry.map(function (value, key) {
              subIndustrylist.push(value.title);
            });
            self.post.subIndustry = subIndustrylist.join(", ");
            let list = [];
            responseData.skills.map(function (value, key) {
              list.push(value.title);
            });
            self.post.skills = list.join(", ");
            store.commit("showLoader", false); // Loader Off
          }
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });
  },
  methods: {
    getStatusLabel(getStatus) {
      let valObj = " ";
      valObj = this.statusOption.filter(function (elem) {
        if (elem.value == getStatus) return elem.value;
      });
      if (valObj.length > 0) return valObj[0].label;
    },

    beforeBlocked() {
      let self = this;
      self.post.reason = "";
      if (self.post.isActive === 1) {
        this.largeModal = true;
      } else {
        this.blockPost(self.statusId);
      }
    },

    blockPost(statusId) {
      let self = this;
      self.submitted = true; //Enable Button
      if (self.post.isActive === 1) {
        if (self.post.reason === "") {
          self.submitted = false; //Enable Button
          return false;
        }
        this.$swal({
          title: this.$lang.common.status.want_to_block,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#e55353",
          confirmButtonText: this.$lang.common.status.yes_block,
          cancelButtonText: this.$lang.common.status.cancel,
        }).then((result) => {
          if (result.value) {
            const id = this.$route.params.id;

            setTimeout(() => {
              let self = this;
              const postData = {
                status: statusId,
                reason: self.post.reason,
              };
              let status, button;
              if (self.statusId === "1") {
                self.statusId = "2";
                self.statusColor = "danger";
                status = this.getStatusLabel(1);
                button = "Block";
              } else {
                self.statusId = "1";
                self.statusColor = "success";
                status = "Blocked";
                button = this.getStatusLabel(1);
              }
              axios
                  .post(this.statusUrlWeb(this.module, id), postData)
                  .then(function (response) {
                    if (response.data.code === 200) {
                      self.post.isStatus = status;
                      self.statusLabel = button;
                      self.largeModal = false;
                      self.submitted = false; //Enable Button
                      self.$router.go();
                    } else {
                      self.err_msg = response.data.message;
                      self.dismissCountDown = 10;
                    }
                  })
                  .catch(function (error) {
                    self.submitted = false; //Enable Button
                  });
            }, 500);
          }
        });
      } else {
        let self = this;
        const id = this.$route.params.id;
        const postData = {
          status: statusId,
          reason: null,
        };
        let status, button;
        if (self.statusId === "1") {
          self.statusId = "2";
          self.statusColor = "danger";
          status = this.getStatusLabel(1);
          button = "Block";
        } else {
          self.statusId = "1";
          self.statusColor = "success";
          status = "Blocked";
          button = this.getStatusLabel(1);
        }
        axios
            .post(this.statusUrlWeb(this.module, id), postData)
            .then(function (response) {
              if (response.data.code === 200) {
                self.post.isStatus = status;
                self.statusLabel = button;
                self.largeModal = false;
                self.submitted = false; //Enable Button
                self.$router.go();
              } else {
                self.err_msg = response.data.message;
                self.dismissCountDown = 10;
              }
            })
            .catch(function (error) {
              self.submitted = false; //Enable Button
            });
      }
    },

  },
};
</script>
